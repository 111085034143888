import * as React from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const AboutUs = () => (
  <Layout>
	<Helmet>
        <script src={'/js/main.js'} type="text/javascript" />
    </Helmet>
    <Seo title="About Us" description="We have the audacity to imagine better health outcomes for patients, and we are pushing science further to reach our goals" />
    <section className="spacer micro"></section>
	<section className="spacer micro"></section>
	<section className="wrap center inspired">
	<img src={"/images/who-we-are-inspired.svg"} alt="Who we are inspired" />
	<p><strong id="inspired">to identify bold ideas—and to relentlessly execute to bring them to reality</strong></p>
	</section>
	
	<section className="wrap about-us">
	<h2>Arena Pharmaceuticals is a team with a singular focus—deliver important medicines to patients.</h2> 
	<p>We have the audacity to imagine better health outcomes for patients, and we are pushing science further to reach our goals. We explore every opportunity to understand the value our molecules may bring to patients—especially to those with unmet treatment needs.</p><br />
	
	<h2>We have the heart to focus on the gut—Arena is committed to the gastroenterology community.</h2> 
	<p>Arena aspires to make real progress for patients, and we are working with the gastroenterology community to help surround patients with support, education, and empathy. </p>
	</section>
	<section className="wrap large-title">
	<h3>Care More. <br className="mobileOnly"/>Act Differently.™</h3>
	</section>
	
	
	
	 
	  <section className="spacer"></section>
	<footer className="definitions">IBD=inflammatory bowel disease. <br />
	<span>Arena is a clinical stage pharmaceutical company that does not have any medicines or products approved for use by any health authority.</span>
</footer>
  </Layout>
)

export default AboutUs
